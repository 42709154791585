// import img1 from './001.jpg';
import img2 from './002.jpg';
// import img3 from './003.jpg';
// import img4 from './004.jpg';
// import img5 from './005.jpg';
// import img6 from './006.jpg';
import img7 from './007.jpg';
// import img8 from './008.jpg';
import img9 from './009.jpg';
import img10 from './010.jpg';
import img11 from './011.jpg';
// import img12 from './012.jpg';
import img13 from './013.jpg';
import img14 from './014.jpg';
// import img15 from './015.jpg';
// import img16 from './016.jpg';
import img17 from './017.jpg';
import img18 from './018.jpg';
import img19 from './019.jpg';
// import img20 from './020.jpg';
// import img21 from './021.jpg';
// import img22 from './022.jpg';
// import img23 from './023.jpg';
// import img24 from './024.jpg';
import img25 from './025.jpg';
import img26 from './026.jpg';

// eslint-disable-next-line import/no-anonymous-default-export
export default [
    // img1,
    img2,
    // img3,
    // img4,
    // img5,
    // img6,
    img7,
    // img8,
    img9,
    img10,
    img11,
    // img12,
    img13,
    img14,
    // img15,
    // img16,
    img17,
    img18,
    img19,
    // img20,
    img26,
    // img21,
    // img22,
    // img23,
    // img24,
    img25,
];
