import React, {useEffect} from 'react';
import styled from 'styled-components';
import Flickity from 'flickity';
import 'flickity-fade';
import 'flickity/dist/flickity.min.css';

import logo from '../assets/logo.png';
import img1 from '../assets/main-carousel/001.jpg';
import img2 from '../assets/main-carousel/002.jpg';
import img3 from '../assets/main-carousel/004.jpg';
import img4 from '../assets/main-carousel/006.jpg';

const SlideshowSection = () => {
    useEffect(() => {
        // @ts-ignore
        new Flickity(document.querySelector('#main-carousel'), {
            autoPlay: 5000,
            draggable: false,
            fade: true,
            lazyLoad: 1,
            pageDots: false,
            pauseAutoPlayOnHover: false,
            prevNextButtons: false,
        });
    }, []);

    return (
        <Wrapper>
            <Carousel id="main-carousel">
                <CarouselItem>
                    <CarouselImage src={img1} />
                </CarouselItem>
                <CarouselItem>
                    <CarouselImage src={img2} />
                </CarouselItem>
                <CarouselItem>
                    <CarouselImage src={img3} />
                </CarouselItem>
                <CarouselItem>
                    <CarouselImage src={img4} />
                </CarouselItem>
            </Carousel>

            <Background/>

            <ContainerWrapper>
                <div className="container">
                    <Container>
                        <Header>
                            <Logo src={logo} alt=""/>
                            <Contacts>
                                <Contact href="https://goo.gl/maps/dzTCMQZimf9Jv4Hm6" target="_blank">Иваново, Бубнова, 40А</Contact>
                                <Contact href="tel:+74932343366">+7 (4932) 34-33-66</Contact>
                            </Contacts>
                        </Header>

                        <Content>
                            <Title>
                                Самый <TitleSub>атмосферный</TitleSub> барбершоп
                            </Title>

                            <TargetButton href="https://n694684.yclients.com/">Записаться</TargetButton>
                        </Content>

                        <Footer>
                            <FooterContact href="https://vk.com/peakyblade_ivanovo" target="_blank">ВК</FooterContact>
                            <FooterContact href="https://wa.me/79051093366" target="_blank">WhatsApp</FooterContact>
                            <FooterContact href="https://t.me/peakyblade_ivanovo37" target="_blank">Telegram</FooterContact>
                        </Footer>
                    </Container>
                </div>
            </ContainerWrapper>
        </Wrapper>
    );
};

const Background = styled.div`
    background: linear-gradient(180deg, rgba(28, 28, 28, 0.16) 38%, #1c1c1c 97%);
    bottom: 0;
    left: 0;
    height: 100%;
    position: absolute;
    width: 100%;
`;

const Carousel = styled.div`
    height: 100%;
    width: 100%;
`;

const CarouselItem = styled.div`
    height: 100%;
    width: 100%;
`;

const CarouselImage = styled.img`
    display: block;
    height: 100%;
    object-fit: cover;
    object-position: center;
    width: 100%;
`;

const Contact = styled.a`
    color: #fff;
    cursor: pointer;
    display: block;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 2px;
    line-height: 18px;
    text-decoration: none;
    text-transform: uppercase;
    white-space: nowrap;
    
    &:hover {
        opacity: 0.7;
    }
    
    @media (min-width: 992px) {
        font-size: 16px;
        letter-spacing: 3px;
    }
`;

const Contacts = styled.div`
    display: grid;
    grid-row-gap: 5px;
    text-align: center;
    
    @media (min-width: 576px) {
        justify-items: end;
    }
    
    @media (min-width: 992px) {
        grid-row-gap: 9px;
    }
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const ContainerWrapper = styled.div`
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    
    & > .container {
        height: 100%;
    }
`;

const Content = styled.div`
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    
    @media (min-width: 992px) {
        align-items: center;
    }
`;

const Footer = styled.div`
    display: flex;
    justify-content: space-around;
    margin: 20px 0;
    
    @media (min-width: 576px) {
        grid-column-gap: 50px;
        justify-content: flex-end;
    }
    
    @media (min-width: 992px) {
        margin: 50px 0;
    }
`;

const FooterContact = styled.a`
    color: #8d8d8d;
    cursor: pointer;
    display: block;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 3px;
    line-height: 1;
    text-decoration: none;
    text-transform: uppercase;
    white-space: nowrap;
    
    &:hover {
        opacity: 0.7;
    }
    
    @media (min-width: 576px) {
        font-size: 16px;
    }
`;

const Header = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    grid-row-gap: 15px;
    justify-content: center;
    margin: 20px 0;
    
    @media (min-width: 576px) {
        flex-direction: row;
        justify-content: space-between;
    }
    
    @media (min-width: 992px) {
        margin: 50px 0;
    }
`;

const Logo = styled.img`
    display: block;
    height: auto;
    width: 150px;
`;

const TargetButton = styled.a`
    background: #fff;
    display: inline-flex;
    color: #1c1c1c;
    cursor: pointer;
    font-weight: 700;
    letter-spacing: 2px;
    line-height: 1;
    margin-top: 20px;
    padding: 13px 30px;
    text-decoration: none;
    text-transform: uppercase;
    
    &:hover {
        background: #eb1d1d;
        color: #fff;
        transform: scale(1.05);
    }
    
    @media (min-width: 576px) {
        font-size: 18px;
        letter-spacing: 3px;
        padding: 15px 80px;
    }
`;

const Title = styled.h1`
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 3px;
    margin: 0;
    text-align: left;
    text-transform: uppercase;
    
    @media (min-width: 576px) {
        font-size: 30px;
        letter-spacing: 10px;
        line-height: 45px;
    }
    
    @media (min-width: 992px) {
        font-size: 50px;
        letter-spacing: 15px;
        line-height: 70px;
        text-align: center;
    }
    
    @media (min-width: 1200px) {
        font-size: 60px;
        letter-spacing: 20px;
        line-height: 80px;
    }
`;

const TitleSub = styled.span`
    color: #eb1d1d;
`;

const Wrapper = styled.section`
    height: 100vh;
    position: relative;
    width: 100vw;
`;

export default SlideshowSection;
