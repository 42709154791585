import {ServiceInterface} from './models';

export const servicesHead: ServiceInterface[] = [
    {
        info: 'Классические и креативные стрижки от наших мастеров.',
        price: '1 200',
        title: 'Мужская стрижка',
    },
    {
        info: 'Короткая стрижка по 2-3 насадки.',
        price: '900',
        title: 'Стрижка машинкой',
    },
    {
        info: 'Детям до 12 лет. Стрижки всегда выполняются мастерами, имеющими большой опыт в работе с детьми, с учетом особенностей роста детских волос и типа лица Вашего ребенка.',
        price: '1000',
        title: 'Детская стрижка',
    },
    {
        info: 'Между стильной бородой и неопрятностью во внешнем виде варьирует тонкая грань, поэтому наши мастера умеют правильно подобрать подходящий вид бороды и оформить его.',
        price: '800',
        title: 'Оформление бороды',
    },
    {
        info: '',
        price: '500',
        title: 'Укладка, окантовка'
    },
    {
        info: 'Оформление бороды с применением лезвия.',
        price: '1 200',
        title: 'Оформление бороды (ТРАДИЦИОННОЕ)',
    },
    {
        info: 'Бритьё лица лезвием начисто.',
        price: '1 400',
        title: 'Королевское бритьё',
    },
    {
        info: '',
        price: '700',
        title: 'Тонирование головы',
    },
    {
        info: 'Выполним за 1 час 30 минут.',
        price: '1 700',
        title: 'Комплекс (стрижка + оформление бороды)',
    },
    {
        info: '',
        price: '2 200',
        title: 'Комплекс (стрижка + оформление бороды + тонирование)',
    },
    {
        info: 'Выполним за 2 часа.',
        price: '2 200',
        title: 'Комплекс (стрижка + оформление бороды лезвием)',
    },
    {
        info: 'Цена указана за 1 зону.',
        price: '200',
        title: 'Воск',
    },
    {
        info: '',
        price: '500',
        title: 'Чёрная маска',
    },
    {
        info: 'Мужская + детские стрижки.',
        price: '1 900',
        title: 'Отец и сын',
    },
];

export const servicesBasic: ServiceInterface[] = [
    {
        info: 'Классические и креативные стрижки от наших мастеров.',
        price: '1 000',
        title: 'Мужская стрижка',
    },
    {
        info: 'Короткая стрижка по 2-3 насадки.',
        price: '700',
        title: 'Стрижка машинкой',
    },
    {
        info: 'Детям до 12 лет. Стрижки всегда выполняются мастерами, имеющими большой опыт в работе с детьми, с учетом особенностей роста детских волос и типа лица Вашего ребенка.',
        price: '800',
        title: 'Детская стрижка',
    },
    {
        info: 'Между стильной бородой и неопрятностью во внешнем виде варьирует тонкая грань, поэтому наши мастера умеют правильно подобрать подходящий вид бороды и оформить его.',
        price: '700',
        title: 'Оформление бороды',
    },
    {
        info: '',
        price: '400',
        title: 'Укладка, окантовка'
    },
    {
        info: 'Оформление бороды с применением лезвия.',
        price: '1 000',
        title: 'Оформление бороды (ТРАДИЦИОННОЕ)',
    },
    {
        info: 'Бритьё лица лезвием начисто.',
        price: '1 200',
        title: 'Королевское бритьё',
    },
    {
        info: '',
        price: '700',
        title: 'Тонирование головы',
    },
    {
        info: '',
        price: '600',
        title: 'Тонирование бороды',
    },
    {
        info: 'Выполним за 1 час 30 минут.',
        price: '1 500',
        title: 'Комплекс (стрижка + оформление бороды)',
    },
    {
        info: '',
        price: '2 000',
        title: 'Комплекс (стрижка + оформление бороды + тонирование)',
    },
    {
        info: 'Выполним за 2 часа.',
        price: '1 800',
        title: 'Комплекс (стрижка + оформление бороды лезвием)',
    },
    {
        info: 'Цена указана за 1 зону.',
        price: '200',
        title: 'Воск',
    },
    {
        info: '',
        price: '500',
        title: 'Чёрная маска',
    },
    {
        info: 'Мужская + детские стрижки. Если ребёнок старше 12 лет цена будет 1800₽.',
        price: '1 600',
        title: 'Отец и сын',
    },
];
