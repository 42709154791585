import React from 'react';
import styled from 'styled-components';
import { servicesBasic, servicesHead } from '../services';
import { DiscountInterface, ServiceInterface } from '../models';
import discounts from '../discounts';

const ServicesSection = () => {
  return (
    <>
      <section className="section">
        <div className="container">
          <h2>Скидки и акции</h2>

          <Services>
            {discounts.map((discount: DiscountInterface) => (
              <Service>
                <ServiceMain>
                  <ServiceTitle>{discount.title}</ServiceTitle>
                  <ServiceInfo>{discount.description}</ServiceInfo>
                </ServiceMain>
                <ServicePrice>{discount.price}</ServicePrice>
              </Service>
            ))}

            <p style={{textAlign: 'right'}}>* скидки, акции и купоны не суммируются</p>
          </Services>
        </div>
      </section>

      <section className="section">
        <div className="container">
          <h2>Услуги</h2>

          <Services>
            <h3>Старший барбер</h3>

            {servicesHead.map((service: ServiceInterface) => (
              <Service>
                <ServiceMain>
                  <ServiceTitle>{service.title}</ServiceTitle>
                  <ServiceInfo>{service.info}</ServiceInfo>
                </ServiceMain>
                <ServicePrice>{service.price} ₽</ServicePrice>
              </Service>
            ))}

            <h3>Прайс барберов</h3>

            {servicesBasic.map((service: ServiceInterface) => (
              <Service>
                <ServiceMain>
                  <ServiceTitle>{service.title}</ServiceTitle>
                  <ServiceInfo>{service.info}</ServiceInfo>
                </ServiceMain>
                <ServicePrice>{service.price} ₽</ServicePrice>
              </Service>
            ))}
          </Services>
        </div>
      </section>
    </>
  );
};

const Service = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
`;

const ServiceInfo = styled.div`
  font-size: 12px;
  line-height: 1.6;
  margin-top: 5px;

  @media (min-width: 576px) {
    font-size: 14px;
  }

  @media (min-width: 992px) {
    line-height: 1.8;
  }
`;

const ServiceMain = styled.div`
  flex-shrink: 1;
`;

const ServicePrice = styled.div`
  flex-shrink: 0;
  flex-wrap: nowrap;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.4;
  margin-left: 20px;
  text-transform: uppercase;

  @media (min-width: 576px) {
    font-size: 18px;
  }

  @media (min-width: 768px) {
    font-size: 20px;
  }

  @media (min-width: 992px) {
    font-size: 22px;
  }
`;

const ServiceTitle = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 1.4;
  text-transform: uppercase;

  @media (min-width: 576px) {
    font-size: 18px;
  }

  @media (min-width: 768px) {
    font-size: 20px;
  }

  @media (min-width: 992px) {
    font-size: 22px;
  }
`;

const Services = styled.div`
  margin: 0 auto;
  max-width: 700px;
`;

export default ServicesSection;
