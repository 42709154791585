import React from 'react';
import styled from 'styled-components';

import about1 from '../assets/about/001.jpg';
import about2 from '../assets/about/002.jpg';
import about3 from '../assets/about/003.jpg';
import goodPlace from '../assets/good-place.png';


const AboutSection = () => {
    return (
        <section className="section">
            <div className="container">
                <h2>О нас</h2>

                <div className="grid align-center">
                    <div className="col-12 col-lg-6">
                        <Text><span>Peaky Blade</span> — не просто современная цирюльня, а мужской клуб, где можно не только привести в порядок волосы, бороду и усы, но и посмотреть интересные спортивные матчи, послушать приятную музыку, выпив при этом чашку кофе.</Text>

                        <GoodPlace src={goodPlace} alt="Яндекс.Хорошее место" />
                    </div>

                    <div className="col-4 col-lg-2">
                        <img src={about1} alt="О нас" />
                    </div>
                    <div className="col-4 col-lg-2">
                        <img src={about2} alt="О нас" />
                    </div>
                    <div className="col-4 col-lg-2">
                        <img src={about3} alt="О нас" />
                    </div>
                </div>
            </div>
        </section>
    );
};

const Text = styled.p`
    font-size: 18px;
    font-weight: 500;
    line-height: 30px;
    
    & > span {
        color: #eb1d1d;
    }
`;

const GoodPlace = styled.img`
  transform: scale(.8);
  width: 100%;
`;

export default AboutSection;
