import styled from 'styled-components';
import { useCookies } from 'react-cookie';

const CookieNotification = () => {
  const [cookies, setCookie] = useCookies(['showedCookieNotification']);

  const handleClose = () => {
    const currentDate = new Date();
    const nextMonthDate = new Date(currentDate);
    nextMonthDate.setMonth(currentDate.getMonth() + 1);

    setCookie('showedCookieNotification', true, {
      expires: nextMonthDate
    });
  };

  if (cookies?.showedCookieNotification) {
    return null;
  }

  return (
    <Wrapper>
      <Close onClick={handleClose}>x</Close>
      <Title>Мы используем cookie</Title>
      <Text>Продолжая использовать наш сайт, вы автоматически соглашаетесь с обработкой ваших персональных данных и
        использованием cookie в соответствии с нашей <a href="/privacy-policy.pdf" target="_blank">Политикой конфиденциальности</a>.</Text>
    </Wrapper>
  );
};

const Wrapper = styled.div`
    background: #000;
    border-radius: 15px;
    bottom: 20px;
    left: 20px;
    padding: 10px 25px;
    position: fixed;
    width: calc(100% - 90px);

    @media (min-width: 576px) {
        width: 500px;
    }
`;

const Title = styled.div`
    font-weight: 500;
`;

const Text = styled.div`
    line-height: 1.4;
    margin-top: 5px;
    
    a {
        color: inherit;
    }
`;

const Close = styled.div`
    cursor: pointer;
    position: absolute;
    right: 25px;
    top: 10px;
`;

export default CookieNotification;
