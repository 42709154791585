import React from 'react';
import photos from '../assets/photos';
import 'flickity/dist/flickity.min.css';

const PhotosSection = () => {
    return (
        <section className="section">
            <div className="container">
                <h2>Наши фото</h2>

                <div id="image-grid" className="grid">
                    {photos.map(img => (
                        <div className="col-6 col-xs-4 col-sm-3 col-lg-2">
                            <img src={img} alt="Peaky Blade"/>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default PhotosSection;
