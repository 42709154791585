import {DiscountInterface} from './models';

const discounts: DiscountInterface[] = [
    {
        description: '',
        price: '30%',
        title: 'Скидка на пятое посещение',
    },
    {
        description: '',
        price: '20%',
        title: 'Скидка в день рождения',
    },
    // {
    //     description: '',
    //     price: '20%',
    //     title: 'Новым клиентам на все услуги',
    // },
    {
        description: '',
        price: '10%',
        title: 'Скидка за подписку на группу VK и отзыв в Яндекс или 2ГИС',
    },
];

export default discounts;
